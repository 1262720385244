import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const [title, setTitle] = useState('Personal Information');
  const [errorMessage, setErrorMessage] = useState(''); // State to track error message
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Update the title when the route changes
    const session = sessions.find(session => session.path === location.pathname);
    if (session) {
      setTitle(session.title);
      setErrorMessage(''); // Clear error message when route changes
    }
  }, [location.pathname]);

  const sessions = [
    { id: 0, title: 'Instructions', path: '/application/instructions' },
    { id: 1, title: 'Personal Information', path: '/application/personal-information' },
    { id: 2, title: 'Academic Details', path: '/application/academic-information' },   
    { id: 3, title: 'Acedemic Aploads', path: '/application/acedemicaploads' },
    { id: 5, title: 'Finance Details', path: '/application/financedetails' },

    { id: 4, title: 'Resume', path: '/application/resume' },
    { id: 6, title: 'Personal Statement', path: '/application/personal-statement' },
    
  ];

  const checkApplicationExists = () => {
    const applicationId = localStorage.getItem('id');
    return  applicationId !== null && applicationId !== "undefined" && applicationId !== "" 
  };
  
  const checkRefrenceLetterExists = () => {
    const acedemic_detail = localStorage.getItem('acedemic_detail');
    console.log("acedemic_detail",acedemic_detail)
    return acedemic_detail !== null && acedemic_detail !== "undefined" && acedemic_detail !== ""; // Return true if application ID exists, otherwise false
  };


  
  

  const handleNavigation = (session) => {
    // Skip the check for the first and last session
    if (session.id === 1 || session.id === 0) {
      navigate(session.path);
    }  else {
      // Check for application ID in localStorage for other sessions
      if (checkApplicationExists()) {
        console.log("session.path",session.path)
        navigate(session.path);
      } else {
        setErrorMessage("Please create an application first.");
      }
    }
  };

  return (
    <nav>
      <div></div>
      <div className="mb-4">
        <div className="card text-left p-1">
          <div className="card-body p-3">
            <h4 className="card-title mb-2">{title}</h4>

            {/* Conditionally render error message */}
            {errorMessage && <p className="text-danger">{errorMessage}</p>}

            <div className="list-group">
              {sessions.map(session => (
                <div
                  key={session.id}
                  onClick={() => handleNavigation(session)}
                  className={`list-group-item list-group-item-action ${location.pathname === session.path ? 'active' : ''}`}
                  style={{ cursor: "pointer" }}
                >
                  {session.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
