import { lazy, Suspense } from "react";
import PersonalInformation from "./PersonalInformation";
import AcademicInformation from "./AcademicInformation";
import ProfessionalInformation from "./ProfessionalInformation";
import PersonalStatements from "./PersonalStatements";
import ReferenceLetter from "./ReferenceLetter";
import FundingOptions from "./FundingOptions";
import PrivacyPolicyStatement from "./PrivacyPolicyStatement";
import Results from "./Results";
import Dashboard from "./Dashboard";
import ApprovedApplications from "./ApprovedApplications";
import FinalApplications from "./FinalApplications";

import UnderReviewApplications from "./UnderReviewApplications";
import NewApplications from "./NewApplications";
import RejectedApplications from "./RejectedApplications";
import ViewApplication from "./ViewApplication";
import AuthGuard from "../../auth/AuthGuard";
import ApplicationInstructions from "./ApplicationInstructions";
import Resume from "./Resume";
import FinanceDetails from "./FinanceDetails";
import Acedemicaploads from "./AcedemicAploads";
import UnsubmittedReport from "./UnsubmittedReport";
import SignUpReport from "./SignUpReport";
import MaleApplicantReport from "./MaleApplicantReport";
import FemaleApplicantReport from "./FemaleApplicantReport";
import PunjabApplicantReport from "./PunjabApplicantReport";
import SindhApplicantReport from "./SindhApplicantReport";
import KpkApplicantReport from "./KpkApplicantReport";
import BalochistanApplicantReport from "./BalochistanApplicantReport";
import IslamabadApplicantReport from "./IslamabadApplicantReport";
import GbApplicantReport from "./GbApplicantReport";
import AkApplicantReport from "./AkApplicantReport";

const Signup = lazy(() => import("./Signup"));
const Signin = lazy(() => import("./Signin"));
const Homepage = lazy(() => import("./Homepage"));
const Faqs = lazy(() => import("./Faqs"));
const Expert = lazy(() => import("./Experts"));
const Cohorts = lazy(() => import("./Cohorts"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const ResetPassword = lazy(() => import("./reset-password"));

const Error = lazy(() => import("./Error"));

const sessionsRoutes = [
  {
    path: "/signup",
    element: (
      <AuthGuard>
        <Suspense>
          <Signup />
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/signin",
    element: (
      <AuthGuard>
        <Suspense>
          <Signin />
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <Suspense>
          <Homepage />
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/faqs",
    element: (
      <AuthGuard>
        <Suspense>
          <Faqs/>
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/expert",
    element: (
      <AuthGuard>
        <Suspense>
          <Expert/>
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/cohorts",
    element: (
      <AuthGuard>
        <Suspense>
          <Cohorts/>
        </Suspense>
      </AuthGuard>
    ),
  },
  
  {
    path: "/sessions/forgot-password",
    element: (
      <AuthGuard>
        <Suspense>
          <ForgotPassword />
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/sessions/reset-password",
    element: (
      <AuthGuard>
        <Suspense>
          <ResetPassword />
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/error/404",
    element: (
      <AuthGuard>
        <Suspense>
          <Error />
        </Suspense>
      </AuthGuard>
    ),
  },
  {
    path: "/application/instructions",
    element: (
      <AuthGuard>
        <ApplicationInstructions />
      </AuthGuard>
    ),
  },
  {
    path: "/application/personal-information",
    element: (
      <AuthGuard>
        <PersonalInformation />
      </AuthGuard>
    ),
  },
  {
    path: "/application/academic-information",
    element: (
      <AuthGuard>
        <AcademicInformation />
      </AuthGuard>
    ),
  },

  {
    path: "/application/professional-information",
    element: (
      <AuthGuard>
        <ProfessionalInformation />
      </AuthGuard>
    ),
  },
  {
    path: "/application/personal-statement",
    element: (
      <AuthGuard>
        <PersonalStatements />
      </AuthGuard>
    ),
  },
  {
    path: "/application/reference-letter",
    element: (
      <AuthGuard>
        <ReferenceLetter />
      </AuthGuard>
    ),
  },
  {
    path: "/application/funding-options",
    element: (
      <AuthGuard>
        <FundingOptions />
      </AuthGuard>
    ),
  },
  {
    path: "/application/privacy-policy-statement",
    element: (
      <AuthGuard>
        <PrivacyPolicyStatement />
      </AuthGuard>
    ),
  },
  {
    path: "/application/resume",
    element: (
      <AuthGuard>
        <Resume />
      </AuthGuard>
    ),
  },
  {
    path: "/application/financedetails",
    element: (
      <AuthGuard>
        <FinanceDetails />
      </AuthGuard>
    ),
  },
  
  {
    path: "/application/acedemicaploads",
    element: (
      <AuthGuard>
        <Acedemicaploads />
      </AuthGuard>
    ),
  },
  
  {
    path: "/application/status",
    element: (
      <AuthGuard>
        <Results />
      </AuthGuard>
    ),
  },
  // Dashboard Routes
  {
    path: "/dashboard",
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
  },
  {
    path: "/applications/new-applications",
    element: (
      <AuthGuard>
        <NewApplications />
      </AuthGuard>
    ),
  },
  {
    path: "/applications/approved-applications",
    element: (
      <AuthGuard>
        <ApprovedApplications />
      </AuthGuard>
    ),
  },
  {
    path: "/applications/final-applications",
    element: (
      <AuthGuard>
        <FinalApplications />
      </AuthGuard>
    ),
  },
  {
    path: "/applications/under-review-applications",
    element: (
      <AuthGuard>
        <UnderReviewApplications />
      </AuthGuard>
    ),
  },
  {
    path: "/applications/rejected-applications",
    element: (
      <AuthGuard>
        <RejectedApplications />
      </AuthGuard>
    ),
  },
  {
    path: "/applications/view-application/:id",
    element: (
      <AuthGuard>
        <ViewApplication />
      </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/not-submitted",
    element: (
      <AuthGuard>
        <UnsubmittedReport />
      </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/signup",
    element: (
      <AuthGuard>
        <SignUpReport />
      </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/male",
    element: (
        <AuthGuard>
          <MaleApplicantReport />
        </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/female",
    element: (
        <AuthGuard>
          <FemaleApplicantReport />
        </AuthGuard>
    ),
  },{
    path: "/applicant/report/punjab",
    element: (
        <AuthGuard>
          <PunjabApplicantReport />
        </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/sindh",
    element: (
        <AuthGuard>
          <SindhApplicantReport />
        </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/kpk",
    element: (
        <AuthGuard>
          <KpkApplicantReport />
        </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/balochistan",
    element: (
        <AuthGuard>
          <BalochistanApplicantReport />
        </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/isb",
    element: (
        <AuthGuard>
          <IslamabadApplicantReport />
        </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/gb",
    element: (
        <AuthGuard>
          <GbApplicantReport />
        </AuthGuard>
    ),
  },
  {
    path: "/applicant/report/ak",
    element: (
        <AuthGuard>
          <AkApplicantReport />
        </AuthGuard>
    ),
  },
  //Dashboard Routes End
];

export default sessionsRoutes;
