import React, { useState, useEffect } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import DashboardHeader from './DashboardHeader';
import Navbar from "./Navbar";

const FinanceDetails = () => {
  const [financeDetails, setFinanceDetails] = useState({
    id: null,
    profession: "",
    income: "",
    dependents: "",
    scholarship: "No",
    organization: "",
    amount: "",
    payment_frequency: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const applicationId = parseInt(localStorage.getItem("id"), 10);

  // Fetch existing finance details
  useEffect(() => {
    const fetchFinanceDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL_strapi}/finance-details?filters[application][id][$eq]=${applicationId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch finance details");
        }

        const data = await response.json();

        if (data.data && data.data.length > 0) {
          const finance = data.data[0];
          setFinanceDetails({
            id: data.data[0].id,
            profession: finance.profession || "",
            income: finance.income || "",
            dependents: finance.dependents || "",
            scholarship: finance.scholarship ? "Yes" : "No", // Convert boolean to "Yes" or "No"
            organization: finance.organization || "",
            amount: finance.amount || "",
            payment_frequency: finance.payment_frequency || "",
            documentid:finance.documentId
          });
        }
      } catch (error) {
        console.error("Error fetching finance details:", error);
      }
    };

    fetchFinanceDetails();
  }, [applicationId, token]);

  const handleInputChange = (field, value) => {
    setFinanceDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!financeDetails.profession) newErrors.profession = "Profession is required.";
    if (!financeDetails.income) newErrors.income = "Income is required.";
    if (!financeDetails.dependents) newErrors.dependents = "Number of dependents is required.";

    if (financeDetails.scholarship === "Yes") {
      if (!financeDetails.organization) newErrors.organization = "Organization name is required.";
      if (!financeDetails.amount) newErrors.amount = "Amount is required.";
      if (!financeDetails.payment_frequency)
        newErrors.payment_frequency = "Payment frequency is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   console.log("did it come here ",financeDetails)
    if (!validateForm()) {
      return;
    }
    console.log("did it come here 2")

    try {
      const financeData = {
        profession: financeDetails.profession,
        income: financeDetails.income,
        dependents: financeDetails.dependents,
        scholarship: financeDetails.scholarship === "Yes" ? true : financeDetails.scholarship === "No" ? false : null,
        organization: financeDetails.scholarship === "Yes" ? financeDetails.organization : "",
        amount: financeDetails.scholarship === "Yes" ? Number(financeDetails.amount) || null : null, // Convert to number or null
        payment_frequency:
          financeDetails.scholarship === "Yes" ? financeDetails.payment_frequency : "",
      };

      let response;

      if (financeDetails.id) {
        response = await fetch(
          `${process.env.REACT_APP_BASE_URL_strapi}/finance-details/${financeDetails.documentid}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: financeData }),
          }
        );
      } else {
        response = await fetch(`${process.env.REACT_APP_BASE_URL_strapi}/finance-details`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: {...financeData ,applicationId}, }) // Send the new data
        });
      }

      if (!response.ok) {
        throw new Error("Failed to save finance details");
      }

      NotificationManager.success("Finance details saved successfully!");
      navigate("/next-section");
    } catch (error) {
      console.error("Error submitting finance details:", error);
      NotificationManager.error("Failed to save finance details. Please try again.");
    }
  };

  return (
  
    <div>
    {/* Header Start */}
    <div className="mb-5">
      <DashboardHeader />
    </div>


    <div className="container row justify-content-center m-auto mb-5">
      <div className="col-md-10">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4">
            <Navbar />
          </div>
          <div className="col-sm-12 col-md-8 mb-4">
            {/* <MyForm /> */}
            
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body">
                    <h4>Finance Details</h4>
                    <h5>Basic Information</h5>
                    <div className="form-group mb-3">
                    <div className="form-group">

                           <label>Profession of Father/Guardian</label>
                          <input
                            type="text"
                            className="form-control"
                            value={financeDetails.profession}
                            onChange={(e) => handleInputChange("profession", e.target.value)}
                          />
                        {errors.profession && <p className="text-danger">{errors.profession}</p>}
                        </div>
                                    </div>
                                    <div className="form-group">
          <label>Income (Annual) of Father/Guardian</label>
          <input
            type="number"
            className="form-control"
            value={financeDetails.income}
            onChange={(e) => handleInputChange("income", e.target.value)}
          />
          {errors.income && <p className="text-danger">{errors.income}</p>}
        </div>

                                    <div className="form-group">

                                    <label>Number of Dependents on Father/Guardian</label>
          <input
            type="number"
            className="form-control"
            value={financeDetails.dependents}
            onChange={(e) => handleInputChange("dependents", e.target.value)}
          />
          {errors.dependents && <p className="text-danger">{errors.dependents}</p>}
</div>
          <div className="form-group">
      <label>Are you getting any scholarship or stipends from any organization?</label>
          <div>
            <label className="mr-3">
              <input
                type="radio"
                name="scholarship"
                value="Yes"
                checked={financeDetails.scholarship === "Yes"}
                onChange={(e) => handleInputChange("scholarship", e.target.value)}
              />{" "}
              Yes
            </label>
            
            <label>
              <input
                type="radio"
                name="scholarship"
                value="No"
                checked={financeDetails.scholarship === "No"}
                onChange={(e) => handleInputChange("scholarship", e.target.value)}
              />{" "}
              No
            </label>
          </div>
          </div>

          {financeDetails.scholarship === "Yes" && (
          <>
            <div className="form-group">
              <label>Name of Organization</label>
              <input
                type="text"
                className="form-control"
                value={financeDetails.organization}
                onChange={(e) => handleInputChange("organization", e.target.value)}
              />
              {errors.organization && <p className="text-danger">{errors.organization}</p>}
            </div>

            <div className="form-group">
              <label>Receiving Amount</label>
              <input
                type="number"
                className="form-control"
                value={financeDetails.amount}
                onChange={(e) => handleInputChange("amount", e.target.value)}
              />
              {errors.amount && <p className="text-danger">{errors.amount}</p>}
            </div>

            <div className="form-group">
              <label>Amount received is:</label>
              <div>
                <label className="mr-3">
                  <input
                    type="radio"
                    name="payment_frequency"
                    value="Monthly"
                    checked={financeDetails.payment_frequency === "Monthly"}
                    onChange={(e) => handleInputChange("payment_frequency", e.target.value)}
                  />{" "}
                  Monthly
                </label>
                <label className="mr-3">
                  <input
                    type="radio"
                    name="payment_frequency"
                    value="Annually"
                    checked={financeDetails.payment_frequency === "Annually"}
                    onChange={(e) => handleInputChange("payment_frequency", e.target.value)}
                  />{" "}
                  Annually
                </label>
                <label>
                  <input
                    type="radio"
                    name="payment_frequency"
                    value="Completion on Degree"
                    checked={financeDetails.payment_frequency === "Completion on Degree"}
                    onChange={(e) => handleInputChange("payment_frequency", e.target.value)}
                  />{" "}
                  Completion on Degree
                </label>
              </div>
              {errors.payment_frequency && (
                <p className="text-danger">{errors.payment_frequency}</p>
              )}
            </div>
          </>
        )}

                    

                  </div>
                  <div className="card-footer">
                    <div className="mc-footer">
                      <div className="row text-center">
                        <div className="col-lg-12 ">

                          <button type="submit" className="btn btn-primary text-white m-1">
                            Save & Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            
          </div>
        </div>
      </div>

    </div>

    {/* Main End */}
    <NotificationContainer />
  </div>
  );
};

export default FinanceDetails;
